import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { navigate } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
    handlePayment: () => void;
};

const ActionBar = ({ handlePayment }: Props): JSX.Element => {
    const { t } = useTranslation();
    return (
        <Grid item>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate("/dashboard/")}
                    >
                        {t("Go back")}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePayment}
                    >
                        {t("Buy")}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ActionBar;
