import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import { navigate } from "gatsby";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppLayout from "../../components/AppLayout/index";
import ActionBar from "../../components/CreditSelection/ActionBar/index";
import ErrorDialog from "../../components/ErrorDialog";
import Loader from "../../components/Loader";
import { useStripeService } from "../../services/stripe";
import * as style from "./style.module.scss";

const CreditSelection = (): JSX.Element => {
    if (process.env.GATSBY_TOGGLE_STRIPE === "false") {
        navigate("/dashboard");
    }

    const eurosPerCredit = 5;
    const { t } = useTranslation();
    const creditBlocks = [
        {
            amount: 60,
            price: 60 * eurosPerCredit,
        },
        {
            amount: 20,
            price: 20 * eurosPerCredit,
        },
        {
            amount: 5,
            price: 5 * eurosPerCredit,
        },
    ];

    const [chosenCreditAmount, setChosenCreditAmount] = useState<number>(20);

    const { loading, error, updateCreditAmount, resetError } =
        useStripeService();

    const handlePayment = () => {
        updateCreditAmount(chosenCreditAmount);
    };

    return (
        <>
            <AppLayout>
                <Grid container spacing={2} direction="column">
                    <Loader loading={loading}>
                        <ActionBar handlePayment={handlePayment} />
                        <Grid item>
                            <RadioGroup
                                aria-label="credit"
                                name="gender1"
                                value={chosenCreditAmount}
                                onChange={event =>
                                    setChosenCreditAmount(
                                        parseInt(event.target.value),
                                    )
                                }
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    justifyContent="center"
                                >
                                    {creditBlocks.map((block, index) => (
                                        <Grid item xs={4} key={index}>
                                            <Grid
                                                container
                                                direction="column"
                                                alignContent="center"
                                                className={style.creditBlock}
                                            >
                                                <Box
                                                    className={
                                                        style.radioButton
                                                    }
                                                >
                                                    <FormControlLabel
                                                        value={block.amount}
                                                        control={
                                                            <Radio
                                                                checked={
                                                                    block.amount ===
                                                                    chosenCreditAmount
                                                                }
                                                            />
                                                        }
                                                        label=""
                                                    />
                                                </Box>
                                                <Box mt={3}>
                                                    <Typography
                                                        variant="h3"
                                                        align="center"
                                                        className={style.amount}
                                                    >
                                                        {block.amount}
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    align="center"
                                                >
                                                    {t("Credits")}
                                                </Typography>
                                                <Box mt={3}>
                                                    <Typography
                                                        variant="h5"
                                                        align="center"
                                                        className={style.price}
                                                    >
                                                        € {block.price}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </RadioGroup>
                        </Grid>
                        <Grid item>
                            <Box className={style.textBlock}>
                                <Typography variant="body2">
                                    {t("CreditPageFreeText")}
                                </Typography>
                            </Box>
                        </Grid>
                    </Loader>
                </Grid>
                <ErrorDialog error={error} reset={resetError} />
            </AppLayout>
        </>
    );
};

export default CreditSelection;
